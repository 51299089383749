<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <h1>
      https://app.ticketmaster.com/discovery/v2/events?keyword={{
        $route.params.eventName
      }}&apikey=C1zETHjzG9nLxALw3JlbfE4kfWx7TVXW&city={{
        $route.params.city
      }}&state={{ $route.params.state }}
    </h1>
  </main>
</template>

<script>
import axios from "axios";
// import { response } from 'express';

export default {
  data() {
    console.log(this.$route.params);
    var name = this.$route.params.eventName;
    var city = this.$route.params.city;
    var state = this.$route.params.state;
    name = name.replace("%20", " ");
    //console.log(name);
    name = name.split("-");

    name = name[0];
    if (name.length < 1) {
      name = name.substring(0, name.length - 1);
    }

    //console.log(name);
    axios
      .get(
        `https://app.ticketmaster.com/discovery/v2/events?keyword=${name}&apikey=C1zETHjzG9nLxALw3JlbfE4kfWx7TVXW&city=${city}&state=${state}`
      )
      .then(response => {
        console.log(response.data._embedded.events[0].url);
        window.location.href = response.data._embedded.events[0].url;
      });
  }
};
</script>
